/* You can add global styles to this file, and also import other style files */
@layer base {
  html,
  body {
    @apply bg-[#15151B] font-['Poppins'];
  }

  .text-outline {
    -webkit-text-stroke: 1px #fff;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
